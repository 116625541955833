import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import { KoreaLayout } from "@/components/layout"
import SEO from "../../components/seo"
import TabsComponent from "./tab"
import * as Styles from "./index.module.scss"
import { KoreaForm } from "../../components/form/KoreaForm"

/* ==================================================
  画像の読み込み
================================================== */
import arrow from "../../images/korea/message/arrow.svg"
import tamKoreaLogo from "../../images/korea/jumbotron/tamchang.png"

import messageImg01 from "../../images/korea/message/illust01.png"
import messageImg01w from "../../images/korea/message/illust01.webp"
import messageImg01Sp from "../../images/korea/message/illust01_sp.png"
import messageImg01Spw from "../../images/korea/message/illust01_sp.webp"

import companyHeading from "../../images/korea/company/company_kr.svg"
import companyHeadingSp from "../../images/korea/company/company_kr_sp.svg"
import companyImg from "../../images/korea/company/company.svg"
import companyImgSp from "../../images/korea/company/company_sp.svg"

import ServiceIcon01 from "../../images/korea/plan/icon_research.svg"
import ServiceIcon02 from "../../images/korea/plan/icon_strategy.svg"
import ServiceIcon03 from "../../images/korea/plan/icon_sns.svg"
import ServiceIcon04 from "../../images/korea/plan/icon_create.svg"
import ServiceIcon05 from "../../images/korea/plan/icon_influencer.svg"
import ServiceIcon06 from "../../images/korea/plan/icon_advertise.svg"

import resultCompany01 from "../../images/korea/result/company01.svg"
import resultCompany02 from "../../images/korea/result/company02.svg"
import resultCompany03 from "../../images/korea/result/company03.svg"
import resultCompany04 from "../../images/korea/result/company04.svg"
import resultCompany05 from "../../images/korea/result/company05.svg"
import resultCompany06 from "../../images/korea/result/company06.svg"
import resultCompany07 from "../../images/korea/result/company07.svg"
import resultCompany08 from "../../images/korea/result/company08.svg"
import resultCompany09 from "../../images/korea/result/company09.svg"
import resultCompany10 from "../../images/korea/result/company10.svg"
import resultCompany11 from "../../images/korea/result/company11.svg"
import resultCompany12 from "../../images/korea/result/company12.svg"
import resultCompany13 from "../../images/korea/result/company13.svg"
import resultCompany14 from "../../images/korea/result/company14.svg"
import resultCompany15 from "../../images/korea/result/company15.svg"
import resultCompany16 from "../../images/korea/result/company16.svg"
import resultCompany17 from "../../images/korea/result/company17.svg"
import resultCompany18 from "../../images/korea/result/company18.svg"
import resultCompany19 from "../../images/korea/result/company19.svg"
import resultCompany20 from "../../images/korea/result/company20.svg"
import resultCompany21 from "../../images/korea/result/company21.svg"
import resultCart01 from "../../images/korea/result/cart01.svg"
import resultCart02 from "../../images/korea/result/cart02.svg"
import resultCart03 from "../../images/korea/result/cart03.svg"
import resultCart04 from "../../images/korea/result/cart04.svg"
import resultCart05 from "../../images/korea/result/cart05.svg"
import resultCart06 from "../../images/korea/result/cart06.svg"
import resultCart07 from "../../images/korea/result/cart07.svg"
import resultCart08 from "../../images/korea/result/cart08.svg"
import resultCart09 from "../../images/korea/result/cart09.svg"

// companyロゴをオブジェクトに格納
const supportLogos = [
  { src: resultCompany01, alt: "KYOCERA Logo" },
  { src: resultCompany02, alt: "Panasonic Logo" },
  { src: resultCompany03, alt: "Nestle Logo" },
  { src: resultCompany04, alt: "FOSSIL Logo" },
  { src: resultCompany05, alt: "TOKYO METROPOLITAN GOVERNMENT Logo" },
  { src: resultCompany06, alt: "ANA Logo" },
  { src: resultCompany07, alt: "TATUNG Logo" },
  { src: resultCompany08, alt: "YANMAR Logo" },
]

const supportListItems = supportLogos.map((logo, index) => (
  <li key={index} className={Styles.topResultSupportItem}>
    <img
      src={logo.src}
      alt={logo.alt}
      width="164"
      height="74"
      className={Styles.topResultSupportImage}
    />
  </li>
))

const ecLogos = [
  { src: resultCompany09, alt: "Belle Maison Logo" },
  { src: resultCompany10, alt: "Daimaru Matsuzakaya Department Stores Logo" },
  { src: resultCompany11, alt: "Kohnan Logo" },
  { src: resultCompany12, alt: "HIMARAYA Logo" },
  { src: resultCompany13, alt: "AOYAMA TAILOR Logo" },
  { src: resultCompany14, alt: "SUIT SQUARE Logo" },
  { src: resultCompany15, alt: "hare:kari AOYAMA Logo" },
  { src: resultCompany16, alt: "SHIMA SEIKI Logo" },
]

// ロゴとaltテキストを使用してリストアイテムを生成
const ecListItems = ecLogos.map((logo, index) => (
  <li key={index} className={Styles.topResultEcItem}>
    <img
      src={logo.src}
      alt={logo.alt}
      width="164"
      height="74"
      className={Styles.topResultSupportImage}
    />
  </li>
))

// companyロゴをオブジェクトに格納
const cartLogos = [
  { src: resultCart01, alt: "shopify Logo" },
  { src: resultCart02, alt: "ecforce Logo" },
  { src: resultCart03, alt: "ecbeing Logo" },
  { src: resultCart04, alt: "futureshop Logo" },
  { src: resultCart05, alt: "aishipR Logo" },
  { src: resultCart06, alt: "W2 Commerce Logo" },
  { src: resultCart07, alt: "ebisumart Logo" },
  { src: resultCart08, alt: "Lexica Logo" },
  { src: resultCart09, alt: "MakeShop Logo" },
]
const cartListItems = cartLogos.map((logo, index) => (
  <li key={index} className={Styles.topResultCartItem}>
    <img
      src={logo.src}
      alt={logo.alt}
      width="164"
      height="74"
      className={Styles.topResultSupportImage}
    />
  </li>
))

const KoreaTopPage: React.VFC = () => {
  return (
    <>
      <SEO
        title="TAMCHANG｜일본 마케팅·크로스보더 E커머스 사이트 구축·일본 진출 지원"
        lang="ko"
        description="일본 진출을 지원하는 일본 마케팅, 크로스보더 E-커머스 사이트 구축 전문가인 일본 디지털 마케팅 기업 TAM의 한국 사업 ‘TAMCHANG’입니다. 일본으로의 사업 확장, 온라인 판매, 일본 시장 리서치를 고려하고 있다면, 언제든지 연락 주세요!"
        image="https://oh-tam.com/korea/ogp_korea.jpg"
        url="https://oh-tam.com/korea"
        canonical="https://oh-tam.com/korea"
        keywords="일본 마케팅,크로스보더 전자상거래,일본어 대응 EC 사이트 구축,크로스보더 E-커머스,E-커머스 사이트 구축,일본 디지털 마케팅,Ecommerce,웹 디자인,일본 진출 지원"
      />
      <KoreaLayout language="ko">
        {/* FV */}
        <section className={Styles.topJumbotron} id={Styles.topJumbotron}>
          <div className={Styles.topJumbotronInner}>
            <div className={Styles.topJumbotronHeadingWrapper}>
              <h1 className={Styles.topJumbotronHeading}>
                <img
                  src={tamKoreaLogo}
                  alt="TAM Korea logo"
                  width="294"
                  height="64"
                />
              </h1>
              <div className={Styles.topJumbotronSubtitleWrapper}>
                <p className={Styles.topJumbotronSubtitle}>
                  Japanese Marketing,
                  <br className={Styles.spOnly} /> E-commerce
                </p>
              </div>
              <div className={Styles.topJumbotronTextWrapper}>
                <p className={Styles.topJumbotronText}>
                  일본 시장을 대상으로
                  <br className={Styles.spOnly} /> 한 국경 넘는
                  <br className={Styles.pcOnly} />
                  전자상거래 마케팅을
                  <br className={Styles.spOnly} /> 일관되게 지원합니다!
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Message */}
        <section className={Styles.topMessage} id={Styles.topMessage}>
          <div className={Styles.topMessageInner}>
            <div className={Styles.topMessageContainer}>
              <h2
                className={`${Styles.topMessageHeading} ${Styles.topHeadingLv2}`}
              >
                <span className={Styles.ko}>메시지</span>
                <span className={Styles.en}>Message</span>
              </h2>
              <p className={Styles.topMessageSubtitle}>
                안녕하세요!
                <br className={Styles.spOnly} /> 일본 시장 진출을 목표로 하는
                한국 기업 여러분께.
              </p>
              <p className={Styles.topMessageText}>
                일본으로의 국경을 넘는 E-커머스 사이트 설립, 프로모션, 브랜드
                인지도를 전면 지원합니다.
                <br />
                우리는 일본의 대기업 및 유명 브랜드의 디지털 마케팅, E-커머스
                전략, 구축, 프로모션 지원에 풍부한 실적을 보유하고 있습니다.
                <br />
                일본 시장에 밝은 일본과 한국의 스태프가 협력하여, 일본의
                소비자와 비즈니스 니즈에 맞는 최적의 계획을 제안하고 실행합니다.
                <br />
                당신의 비즈니스를 일본 시장에서 비상하게 해봅시다！
              </p>
              <div className={Styles.topMessageLinkWrapper}>
                <a
                  href="https://www.tam-tam.co.jp/"
                  className={Styles.topMessageLink}
                  target="_blank"
                >
                  소속된 TAM 그룹에 대해서는 여기를 클릭하세요
                  <img
                    src={arrow}
                    alt="arrow"
                    width="11"
                    height="11"
                    className={Styles.topMessageLinkArrow}
                  />
                </a>
              </div>
            </div>
            <div className={Styles.topMessageImageWrapper}>
              <picture>
                <source media="(max-width: 767px)" srcSet={messageImg01Spw} />
                <source media="(max-width: 767px)" srcSet={messageImg01Sp} />
                <source srcSet={messageImg01w} />
                <img
                  src={messageImg01}
                  alt="TAM member"
                  width="691"
                  height="537"
                  className={Styles.topMessageImage}
                />
              </picture>
            </div>
          </div>
        </section>

        {/* Market */}
        <section className={Styles.topMarket} id={Styles.topMarket}>
          <div className={Styles.topMarketInner}>
            <div className={Styles.topMarketHeadingWrapper}>
              <h2
                className={`${Styles.topMarketHeading} ${Styles.topHeadingLv2}`}
              >
                <span className={Styles.ko}>시장</span>
                <span className={Styles.en}>Market</span>
              </h2>
              <p className={Styles.topMarketSubtitle}>
                일본 대상 국경 넘는
                <br className={Styles.spOnly} /> E-커머스 마케팅에는 기회가
                많아요！
              </p>
            </div>
            <ul className={Styles.topMarketList}>
              <li className={Styles.topMarketItem}>
                <div className={Styles.topMarketDataContainer}>
                  <h3 className={Styles.topMarketHeadingLv3}>
                    01
                    <span>To Customer</span>
                  </h3>
                  <p className={Styles.topMarketItemSubtitle}>
                    일본 시장에서 한국의 인기와 상품 수요는 증가 추세
                  </p>
                  <ul className={Styles.topMarketDataList}>
                    <li className={Styles.topMarketDataItem}>
                      <p className={Styles.topMarketDataItemText}>
                        한국을 방문하는 관광객 중에서 일본인이 가장 많아요
                      </p>
                      <div className={Styles.topMarketDataItemImgWrapper}>
                        <StaticImage
                          placeholder="none"
                          alt="Foreign Tourist Statistics"
                          src="../../images/korea/market/graph01.png"
                          width={335}
                          height={152}
                          className={Styles.topMarketDataItemImg}
                        />
                        <p className={Styles.topMarketDataItemRef}>
                          출처：한국관광공사 '2023년 4월 외국인 관광객 통계'
                        </p>
                      </div>
                    </li>
                    <li className={Styles.topMarketDataItem}>
                      <p className={Styles.topMarketDataItemText}>
                        일본으로의 국가별 화장품 수입액이 1위
                      </p>
                      <div className={Styles.topMarketDataItemNumWrapper}>
                        <div className={Styles.topMarketDataItemNum}>
                          7750
                          <span>억 엔</span>
                        </div>
                        <p className={Styles.topMarketDataItemRef}>
                          출처：일본 수입 화장품 협회 '2022년도 국별 수입액'
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
              <li className={Styles.topMarketItem}>
                <div className={Styles.topMarketDataContainer}>
                  <h3 className={Styles.topMarketHeadingLv3}>
                    02
                    <span>To Business</span>
                  </h3>
                  <p className={Styles.topMarketItemSubtitle}>
                    일본 기업들은 한국 시장 진출에 적극적
                  </p>
                  <ul className={Styles.topMarketDataList}>
                    <li className={Styles.topMarketDataItem}>
                      <p className={Styles.topMarketDataItemText}>
                        해외진출 일본기업의 영업이익에서 2년 연속 1위
                      </p>
                      <div className={Styles.topMarketDataItemImgWrapper}>
                        <StaticImage
                          placeholder="none"
                          alt="Japanese companies expanding overseas"
                          src="../../images/korea/market/graph02.png"
                          width={287}
                          height={151}
                          className={Styles.topMarketDataItemImg}
                        />
                        <p className={Styles.topMarketDataItemRef}>
                          출처：JETRO '2022년도 해외진출 일본기업 현황조사 |
                          한국편'
                        </p>
                      </div>
                    </li>
                    <li className={Styles.topMarketDataItem}>
                      <p className={Styles.topMarketDataItemText}>
                        70% 이상의 닛케이 기업이 한국 판매 기능을 확대할 예정
                      </p>
                      <div className={Styles.topMarketDataItemNumWrapper}>
                        <div className={Styles.topMarketDataItemNum}>
                          74<span className={Styles.dot}>.</span>4
                          <span className={Styles.percent}>%</span>
                        </div>
                        <p className={Styles.topMarketDataItemRef}>
                          출처：JETRO '2022년도 해외진출 일본기업 현황조사 |
                          한국편'
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </section>

        {/* Company */}
        <section className={Styles.topCompany} id="topCompany">
          <div className={Styles.topCompanyInner}>
            <h2
              className={`${Styles.topCompanyHeading} ${Styles.topHeadingLv2}`}
            >
              <picture>
                <source media="(max-width: 767px)" srcSet={companyHeadingSp} />
                <img
                  src={companyHeading}
                  alt="탐그룹"
                  width="464"
                  height="181"
                  className={Styles.topCompanyHeadingImage}
                />
              </picture>
              <span className={Styles.en}>
                <span className={Styles.isSmall}>Global</span>Company
              </span>
            </h2>
            <div className={Styles.topCompanyImageWrapper}>
              <picture className={Styles.topCompanyPic}>
                <source media="(max-width: 767px)" srcSet={companyImgSp} />
                <img
                  src={companyImg}
                  alt="A map showing the locations of the company's offices scattered across the world. Marks are placed in Tokyo and Osaka as central points, with additional offices in Amsterdam, London, Taiwan, Singapore, San Francisco, and Hawaii."
                  width="938"
                  height="494"
                  className={Styles.topCompanyImage}
                />
              </picture>
            </div>
          </div>
        </section>

        <section className={Styles.topPlan} id="topPlan">
          <TabsComponent />
          <div className={Styles.topService} id="topService">
            <div className={Styles.topServiceInner}>
              <h2
                className={`${Styles.topServiceHeading} ${Styles.topHeadingLv2}`}
              >
                <span className={Styles.ko}>서비스 메뉴</span>
                <span className={Styles.en}>
                  Service
                  <br className={Styles.spOnly} /> Menu
                </span>
              </h2>
              <p className={Styles.topServiceSubtitle}>
                요구 사항 및 비즈니스 상황에 따라 플랜을
                <br className={Styles.spOnly} /> 커스터마이즈할 수 있습니다!
              </p>
              <ul className={Styles.topServiceList}>
                <li
                  className={`${Styles.topServiceItem} ${Styles.topServiceItemResearch}`}
                >
                  <img
                    src={ServiceIcon01}
                    alt="research"
                    width="78"
                    height="78"
                    className={Styles.topServiceItemImage}
                  />
                  <p className={Styles.topServiceItemTitle}>일본 시장 조사</p>
                  <p className={Styles.topServiceItemText}>
                    인터넷 및 사용자 청취를 통해 리서치하고, 일본 시장의 잠재력
                    및 사용자 행동 및 취향 등을{" "}
                    <span>저렴하게 조사합니다.</span>
                  </p>
                </li>
                <li
                  className={`${Styles.topServiceItem} ${Styles.topServiceItemStrategy}`}
                >
                  <img
                    src={ServiceIcon02}
                    alt="strategy"
                    width="60"
                    height="73"
                    className={Styles.topServiceItemImage}
                  />
                  <p className={Styles.topServiceItemTitle}>
                    브랜드 구축 및 전략
                  </p>
                  <p className={Styles.topServiceItemText}>
                    현지 사용자의 관심사에 맞는{" "}
                    <span>마케팅/크리에이티브 플랜을 구상하고</span> 실행합니다.
                  </p>
                </li>
                <li
                  className={`${Styles.topServiceItem} ${Styles.topServiceItemSns}`}
                >
                  <img
                    src={ServiceIcon03}
                    alt="sns"
                    width="70"
                    height="79"
                    className={Styles.topServiceItemImage}
                  />
                  <p className={Styles.topServiceItemTitle}>SNS</p>
                  <p className={Styles.topServiceItemText}>
                    Instagram, X, TikTok 등 대상에 맞는 소셜 미디어를 활용하여
                    전략 <span>수립부터 제작, 운영까지 지원합니다.</span>
                  </p>
                </li>
                <li
                  className={`${Styles.topServiceItem} ${Styles.topServiceItemCreate}`}
                >
                  <img
                    src={ServiceIcon04}
                    alt="create"
                    width="71"
                    height="71"
                    className={Styles.topServiceItemImage}
                  />
                  <p className={Styles.topServiceItemTitle}>
                    크리에이티브 제작 및 사이트 구축
                  </p>
                  <p className={Styles.topServiceItemText}>
                    <span>마케팅 전략에 기반한 제작을</span> 진행합니다.
                    기획부터 디자인, 라이팅까지 일관성있게 할 수 있는 것이
                    강점입니다.
                  </p>
                </li>
                <li
                  className={`${Styles.topServiceItem} ${Styles.topServiceItemInfluencer}`}
                >
                  <img
                    src={ServiceIcon05}
                    alt="influencer"
                    width="81"
                    height="58"
                    className={Styles.topServiceItemImage}
                  />
                  <p className={Styles.topServiceItemTitle}>인플루언서 기획</p>
                  <p className={Styles.topServiceItemText}>
                    적합한 인플루언서의{" "}
                    <span>리서치, 기획, 컨택 및 실시 후 효과 측정까지</span>
                    맡겨주실 수 있습니다.
                  </p>
                </li>
                <li
                  className={`${Styles.topServiceItem} ${Styles.topServiceItemAdvertise}`}
                >
                  <img
                    src={ServiceIcon06}
                    alt="advertise"
                    width="70"
                    height="60"
                    className={Styles.topServiceItemImage}
                  />
                  <p className={Styles.topServiceItemTitle}>
                    디지털 광고 설계 및 운영
                  </p>
                  <p className={Styles.topServiceItemText}>
                    일본의 각 광고 플랫폼의 특성과 타겟 사용자의 행동에 맞게{" "}
                    <span>광고 콘텐츠 제작 및 광고 운영을 실시합니다.</span>
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section className={Styles.topResult} id="topResult">
          <div className={Styles.topResultInner}>
            <div className={Styles.topResultBgWhite}>
              <div className={Styles.topResultBgWhiteContainer}>
                <h2
                  className={`${Styles.topResultHeading} ${Styles.topHeadingLv2}`}
                >
                  <span className={Styles.ko}>지원 실적</span>
                  <span className={Styles.en}>
                    <span className={Styles.isSmall}>Support</span>
                    <span>results</span>
                  </span>
                </h2>
                <div className={Styles.topResultSupportContainer}>
                  <div className={Styles.topResultSupportTextWrapper}>
                    <h3
                      className={`${Styles.topResultHeadingLv4} ${Styles.topHeadingLv4}`}
                    >
                      TAM지원실적
                    </h3>
                    <p className={Styles.topResultSupportText}>
                      TAM 그룹이 파트너로서 마케팅을 지원한 기업의 일부를
                      소개합니다.
                    </p>
                  </div>
                  <ul className={Styles.topResultSupportList}>
                    {supportListItems}
                  </ul>
                </div>
                <div className={Styles.topResultEcContainer}>
                  <div className={Styles.topResultEcTextWrapper}>
                    <h3
                      className={`${Styles.topResultHeadingLv4} ${Styles.topHeadingLv4}`}
                    >
                      E-커머스 지원 실적
                    </h3>
                    <p className={Styles.topResultEcText}>
                      지금까지 파트너로서 E-커머스에서 지원한 기업의 일부를
                      소개합니다.
                    </p>
                  </div>
                  <div className={Styles.topResultEcListContainer}>
                    <ul className={Styles.topResultEcList}>{ecListItems}</ul>
                    <ul className={Styles.topResultEcList}>
                      <li className={Styles.topResultEcItem}>
                        <img
                          src={resultCompany17}
                          alt={"INE Logo"}
                          width="164"
                          height="74"
                          className={Styles.topResultSupportImage}
                        />
                      </li>
                      <li className={Styles.topResultEcItem}>
                        <img
                          src={resultCompany18}
                          alt={"Heart Plus Logo"}
                          width="164"
                          height="74"
                          className={Styles.topResultSupportImage}
                        />
                      </li>
                      <li className={Styles.topResultEcItem}>
                        <img
                          src={resultCompany19}
                          alt={"MEDICUS SHUPPAN,Publishers Logo"}
                          width="164"
                          height="74"
                          className={Styles.topResultSupportImage}
                        />
                      </li>
                      <li
                        className={`${Styles.topResultEcItem} ${Styles.spOnly}`}
                      >
                        <img
                          src={resultCompany20}
                          alt={"BRUNCH PARK Logo"}
                          width="164"
                          height="74"
                          className={Styles.topResultSupportImage}
                        />
                      </li>
                      <li
                        className={`${Styles.topResultEcItem} ${Styles.spOnly}`}
                      >
                        <img
                          src={resultCompany21}
                          alt={"BABBI Logo"}
                          width="164"
                          height="74"
                          className={Styles.topResultSupportImage}
                        />
                      </li>
                    </ul>
                    <ul className={Styles.topResultEcList}>
                      <li
                        className={`${Styles.topResultEcItem} ${Styles.pcOnly}`}
                      >
                        <img
                          src={resultCompany20}
                          alt={"BRUNCH PARK Logo"}
                          width="164"
                          height="74"
                          className={Styles.topResultSupportImage}
                        />
                      </li>
                      <li
                        className={`${Styles.topResultEcItem} ${Styles.pcOnly}`}
                      >
                        <img
                          src={resultCompany21}
                          alt={"BABBI Logo"}
                          width="164"
                          height="74"
                          className={Styles.topResultSupportImage}
                        />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className={Styles.topResultBgBeige}>
              <div className={Styles.topResultBgBeigeContainer}>
                <div className={Styles.topResultCartContainer}>
                  <div className={Styles.topResultCartTextWrapper}>
                    <h3
                      className={`${Styles.topResultHeadingLv4} ${Styles.topHeadingLv4} ${Styles.topResultCartHeading}`}
                    >
                      일본만의 EC 카트에도 대응!
                    </h3>
                    <p className={Styles.topResultCartText}>
                      국경을 넘는 E커머스에서 강한 shopify는 물론, 일본의
                      상문화와 마케팅에서 추천하는 카트도 다수 대응 가능합니다.
                      <br />
                      귀사의 일본 사업소의 유무, 상품 특성에 맞춰 어떤 카트를
                      사용할지 조언도 드립니다.
                    </p>
                  </div>
                  <ul className={Styles.topResultCartList}>{cartListItems}</ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <KoreaForm language="ko" />
      </KoreaLayout>
    </>
  )
}

export default KoreaTopPage
